<template>
	<ion-page>
		<ion-progress-bar
			v-if="store.state.isLoading"
			type="indeterminate"
		></ion-progress-bar>
		<ion-content :fullscreen="true" class="ion-padding">
			<div>
				<div class="center">
					<form @submit.prevent="Login">
						<div class="form-content">
							<ion-grid>
								<ion-row>
									<img src="@/assets/logo-dokter.svg" alt="logo" class="logo" />
								</ion-row>
								<ion-row>
									<span class="version">Doctor v.1.1.10-w</span>
								</ion-row>
								<ion-row style="text-align:center" v-if="code.isError">
									<ion-col>
										<ion-label style="color:red;">
											Email tidak terdaftar
										</ion-label>
									</ion-col>
								</ion-row>
								<ion-row
									style="text-align:center"
									v-if="code.internalServerError"
								>
									<ion-col>
										<ion-label style="color:red;">
											Gangguan email server, coba lagi nanti
										</ion-label>
									</ion-col>
								</ion-row>

								<ion-row>
									<ion-col>
										<ion-label position="stacked">Input Email</ion-label>
										<input
											type="email"
											required
											placeholder="name@example.com"
											v-model="email"
											:disabled="code.disabled"
										/>
									</ion-col>
								</ion-row>
								<ion-row>
									<ion-col size="12"
										><ion-button
											type="submit"
											expand="block"
											color="ari"
											:disabled="code.disabled"
											>SIGN IN</ion-button
										></ion-col
									>
								</ion-row>
							</ion-grid>
						</div>
					</form>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonContent,
	IonPage,
	IonLabel,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonProgressBar,
} from "@ionic/vue";
import { inject, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { convertTZ } from "../store/methods/utils/utils";
export default {
	name: "Login",
	components: {
		IonContent,
		IonPage,
		IonLabel,
		IonButton,
		IonGrid,
		IonRow,
		IonCol,
		IonProgressBar,
	},
	setup() {
		const store = inject("store");
		const router = useRouter();
		const email = ref(null);

		//set reactive value
		const code = reactive({
			disabled: false,
			isError: false,
			internalServerError: false,
		});

		//set value if any value on localStorage
		email.value =
			localStorage.getItem("DoctorEmail") != null
				? localStorage.getItem("DoctorEmail")
				: null;

		//Login
		async function Login() {
			localStorage.setItem(
				"DoctorLoginDate",
				convertTZ(new Date(), "Asia/Jakarta").getDate()
			);
			store.state.isLoading = true;
			code.disabled = true;
			code.isError = false;
			const r = await store.userVerification.login(email.value);
			//console.log("data r");

			if (r.status === 200 && r.data.ResponseCode == "000") {
				store.state.isLoading = false;
				code.isError = false;
				router.replace("/verify-code");
			}
			if (r.status === 200 && r.data.ResponseCode == "100") {
				store.state.isLoading = false;
				code.isError = true;
			}
			if (r.status === 200 && r.data.ResponseCode == "99") {
				store.state.isLoading = false;
				code.internalServerError = true;
			}

			if (r.status != null) {
				code.disabled = false;
			}
		}

		return { store, email, Login, code };
	},
};
</script>

<style scoped>
.form-content {
	margin: 0 auto;
}
ion-content {
	--overflow: hidden;
}
ion-label {
	margin-left: 3px;
}

input {
	width: 100%;
	height: 43px;
	border: 1px solid #cacaca;
	border-radius: 10px;
	padding: 0 15px;
	margin: 8px 0;
	padding: 0 10x;
	outline: none;
}
.logo {
	width: 100px;
	height: 100px;
	margin: 0 auto;
	border-radius: 2px;
}
.version {
	margin: 0 auto;
	margin-bottom: 40px;
	color: var(--ion-color-ari);
	margin-top: 5px;
}
</style>
